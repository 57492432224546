var API = {
  // URI: "https://padron.tesetgps.com.ar", //production
  // imgURI: "https://padron.tesetgps.com.ar/api/images",

  URI: "https://inscripciones.tesetgps.com.ar", //production test
  // imgURI: "https://test.tesetgps.com.ar/backend/images",

  // URI: "http://localhost:8080", //develop
  // imgURI: "C://Users/PC-01/Documents/Workspace/sistema-uteam-back/images",
};
export default API;
